.custom-table {
  text-align: center;
}
table.table.Company-details {
  background-color: #fff;
  text-align: left;
}

tr.table-inner-content td {
  color: #6b7280;
  background-color: #fff;
  vertical-align: baseline;
}
tr.table-inner-content td img {
  width: 40px;
}
tr.top-head-table th {
  border: 0;
  color: #275598;
}
.table tbody + tbody {
  border-top: 10px solid #dfe5f0;
}
.footer-table {
  display: flex;
  float: right;
}
select.custom-select {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #d0d0d0;
  border-radius: 0;
}
.home_content.inner-home-content {
  top: 20px;
}
.page-heading.d-flex img {
  margin: -7px 15px 0 0;
}
tr.table-inner-content td h5 {
  font-size: 17px;
  color: #000000;
  font-weight: 600;
}
table.table.Company-details tr td h5 {
  font-size: 16px;
  font-weight: 700;
  color: #383838;
}
table.table.Company-details tr td {
  border: 0;
  padding: 20px;
}
tr.table-inner-content.detail {
  text-align: initial;
}
.items-text.pt-2 h6 {
  font-weight: 600;
}

.white-box-sec {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 #d6d6d6;
}
.white-box-sec label {
  color: #000000;
}

.invoice_status {
  padding: 5px 10px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
}
.search-bar img {
  position: absolute;
  top: 12px;
  left: 14px;
}
.form-group {
  margin-bottom: 1rem;
}
.search-bar input {
  border-radius: 50px;
  height: 45px;
  padding-left: 40px;
  font-weight: 200;
  font-size: 14px;
}
.search-bar {
  width: 30%;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #275598;
  border-color: transparent transparent #275598;
  background-color: transparent !important;
  border-bottom: 3px solid !important;
  font-size: 16px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #949494;
}
.company_details header {
  display: none;
}
.hold {
  background-color: #f2994a !important;
}
.closed {
  background-color: #dc1500 !important;
}
.inprogress {
  background-color: #1477f8 !important;
}
.solved {
  background-color: #6fcf97 !important;
}
.trial_status {
  width: 72px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #ffff;
}
.search_and_add_new {
  display: flex;
  justify-content: flex-end;
}
.custom-tab {
  padding: 8px !important;
}

@media only screen and (max-width: 600px) {
  .nav-tabs {
    flex-direction: column;
    margin-bottom: 10px !important;
  }
  .search-bar {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .search-bar {
    width: 100%;
  }
}
