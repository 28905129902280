.inventory-count {
  margin-bottom: 50px;
}
.custom-tooltip {
  background-color: #275598 !important;
  border-radius: 4px;
  color: white; /* Customize the text color as needed */
}
.inventory-count .dropdown-toggle {
  width: 120px !important;
  border: none;
}
.onHold {
  background-color: #f2994a !important;
}
.closed {
  background-color: #dc1500 !important;
}
.inProgress {
  background-color: #1477f8 !important;
}
.solved {
  background-color: #6fcf97 !important;
}
.dropdown-toggle {
  height: 36px;
}
.modalTitle {
  font-family: "Poppins", Arial, sans-serif;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 26px;
  letter-spacing: -0.32px;
}
.support-issues .rdt_TableBody {
  min-height: 300px;
}
