.dashboard-grid {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 #27559840;
  /* display: flex; */
  /* align-items: center; */
  justify-content: space-between;
}

.dashboard-grid .left-box-grid img {
  width: 80px;
  height: 80px;
}

.dashboard-grid .right-box-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: right;
}

.dashboard-grid .right-box-grid span {
  font-size: 28px;
  font-weight: 700;
}
.dashboard-grid .right-box-grid p {
  font-size: 18px;
  /* text-transform: uppercase; */
  margin: 0;
}
