.itemDetails header {
  background-color: transparent !important;
  padding: 0 !important;
}
.table-inner-content h5 {
  font-size: 17px !important;
  color: #000000;
  font-weight: 600;
}
.itemDetails .search-icon {
  top: 12px;
}
.item_details h6 {
  margin: 0;
}
