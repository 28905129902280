.noti-main {
  display: flex;
  justify-content: space-between;
}
.notification {
  display: flex;
}
.notification .head {
  font-weight: 600;
  color: #275598;
  font-size: 14px;
}
.change-password .eye-icon img {
  position: absolute;
  top: 14px !important;
}
button.add-bank-acc {
  background-color: transparent;
  padding: 0 10px 0 10px;
  height: 40px;
  border-radius: 50px;
  border: 2px solid #275598;
  color: #275598;
  font-weight: 600;
  float: right;
}

.eye_icon {
  position: absolute;
  right: 10px;
  top: 24%;
  cursor: pointer;
}
.changePassword {
  display: flex;
  justify-content: space-between;
}
.changePassword .form-group {
  width: 30%;
}
.fa-eye-slash,
.fa-eye {
  color: #ff4e00;
}
@media only screen and (max-width: 768px) {
  .changePassword {
    display: flex;
    flex-direction: column;
  }
  .changePassword .form-group {
    width: 100%;
  }
}
