.form-control-cus {
  border-radius: 8px;
  border: 1px solid #bbbbbb;
  height: 45px;
  color: #e5e7eb;
}
.otp-input-custom {
  margin: 15px auto;
  width: 85%;
}
.eye-icon {
  position: relative;
}
img.hide-pass {
  position: absolute;
  right: 20px;
  top: 9px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #78788029;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ff4e00;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
a.Solved {
  background-color: #6fcf97;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
}
a.On-Hold {
  background-color: #f2994a;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  color: #fff;
}

p.url-link a {
  color: #ff4e00 !important;
  text-decoration: underline !important;
}
.fa-times-circle {
  display: none;
}
.fa-check-circle {
  display: none;
}
.white-box-sec .react-toggle {
  margin-left: 10px;
}
.error-msg {
  font-size: 12px;
  color: red;
}
.set-price {
  font-size: 18px;
  font-weight: 600;
}
.add_company_setprice .input-group-text {
  background-color: #fff !important;
  border-radius: 0 !important;
  border-right: 0 !important;
  padding-right: 5px !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.add_company_setprice .form-control {
  border-left: 0 !important;
  padding-left: 0 !important;
}
input:-internal-autofill-selected {
  background-color: #fff !important;
}
.form-switch .form-check-input {
  font-size: x-large;
}
.form-check-input:focus {
  border-color: gray !important;
  outline: 0;
  box-shadow: none !important;
  --bs-form-switch-bg: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e);
}

.form-check-input:checked {
  background-color: #ff4e00 !important;
  border-color: #ff4e00 !important;
}
